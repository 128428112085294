<template>
  <div>
    <Dialog
      :header="`${$t('contracts.contractForApplication')} #${
        contract.applicationId
      }`"
      v-model:visible="viewDialog"
      :draggable="false"
      :modal="true"
      :style="{ width: '60vw' }"
    >
      <p class="text-xl m-0">
        {{ $t("contracts.uploadSignContract") }} ({{ $t("global.pdfSmaller") }})
      </p>
      <p class="text-400">{{ $t("contracts.pleaseUploadContract") }}</p>
      <FileUpload
        name="file"
        :multiple="false"
        :maxFileSize="$fileSize"
        :showCancelButton="false"
        :customUpload="true"
        @uploader="uploadFile"
        :invalidFileSizeMessage="`{0}: ${$t('global.invalidFileSize')} {1}.`"
        :invalidFileTypeMessage="`{0}: ${$t('global.invalidFileType')} {1}.`"
        @select="checkFiles"
        @remove="checkFiles"
        :chooseLabel="$t('global.choose')"
        :uploadLabel="$t('global.upload')"
      >
        <template #empty>
          <div class="text-center">
            <span
              alt="Upload contract"
              class="mdi mdi-96px mdi-orange mdi-cloud-upload-outline"
            ></span>
            <p>{{ $t("global.dragAndDrop") }}</p>
            <Button
              :label="$t('contracts.uploadContract')"
              @click="selectFiles"
            ></Button>
          </div>
        </template>
      </FileUpload>
      <template #footer>
        <Button
          :label="$t('global.submit')"
          :class="'p-button-success'"
          @click="accept()"
          icon-pos="right"
          :loading="loading"
          :disabled="disableSubmit"
        />
        <Button
          :label="$t('global.close')"
          :class="'p-button-danger'"
          @click="close()"
        />
        <!-- <template v-if="!checkRole(['certification-manager','quality-assurance-manager','product-manager'])">
        <Button
          id="acceptButton"
          :label="$t('contracts.acceptContract')"
          class="p-button-success"
          v-if="checkStatus"
          @click="acceptRejectContract(true)"
        />

        <Button
          id="rejectButton"
          :label="$t('contracts.rejectContract')"
          class="p-button-danger"
          v-if="checkStatus"
          @click="acceptRejectContract(false)"
        />
        </template> -->
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Accept contract",
  props: ["display", "contractValue"],
  emits: ["closeAcceptContractView"],
  data() {
    return {
      viewDialog: false,
      loading: false,
      buttonElement: "",
      disableSubmit: true,
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        this.$nextTick(() => {
          this.buttonElement = document.querySelector(
            ".p-fileupload-buttonbar"
          );
          if (this.buttonElement) {
            this.buttonElement.style.display = "none";
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["checkRole"]),
    contract() {
      return { ...this.contractValue };
    },
  },
  methods: {
    checkFiles(event) {
      if (event.files.length > 0) {
        this.disableSubmit = false;
      } else {
        this.disableSubmit = true;
      }
    },
    async uploadFile(data) {
      try {
        await this.$store.dispatch("uploadFile", {
          file: data.files[0],
          type: "contract",
          id: this.contract.id,
        });

        this.$notification(
          this.$t("contracts.updateContractStatus"),
          this.$t("contracts.contractAccepted")
        );
        this.$notification(
          this.$t("contracts.updateContractStatus"),
          this.$t("contracts.reloadTableContracts"),
          "info"
        );
        this.loading = false;
        this.close(true);
      } catch (err) {
        console.log(err);
      }
    },
    close(val) {
      this.$emit("closeAcceptContractView", val);
    },
    selectFiles() {
      document.querySelector(".p-fileupload-choose").click();
    },
    async accept() {
      this.loading = true;
      let temp = {
        id: this.contract.id,
        applicationId: this.contract.applicationId,
        salesPersonName: this.contract.salesPersonName,
        companyRepresentativeName: this.contract.companyRepresentativeName,
        status: "accepted",
        comments: this.contract.comments,
        version: this.contract.version,
      };
      await this.$store.dispatch("updateContract", temp).then(() => {
        document.querySelector(".p-fileupload-buttonbar > button").click();
        this.$action(
          "contract-accept",
          {
            contractId: this.contract.id,
            applicationId: this.contract.applicationId,
          },
          "contract"
        );
      });
    },
  },
};
</script>
