<template>
  <ViewContract
    :display="showViewContract"
    :contract="contract"
    @closeViewContract="closeContract"
  >
  </ViewContract>
  <AcceptContractView
    :display="showAcceptContractView"
    :contractValue="contract"
    @closeAcceptContractView="closeAcceptContractView"
  >
  </AcceptContractView>
  <Card>
    <template #content v-if="loading || contracts.length > 0">
      <div class="grid">
        <div class="col-6 md:col-4">
          <h4>{{ $t("contracts.title") }}</h4>
        </div>
        <div
          class="align-self-center col-6 md:col-2 md:col-offset-6 text-right"
        >
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
      </div>
      <DataTable
        :value="contracts"
        :paginator="true"
        :filters="filters"
        :rows="10"
        dataKey="id"
        ref="tableContracts"
        :loading="loading"
        showGridlines
        class="p-datatable-contracts"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        removableSort
        sortField="createdDate"
        :sortOrder="-1"
        filterDisplay="menu"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty>{{ $t("contracts.noContractsFound") }}</template>
        <Column
          field="id"
          :showFilterMatchModes="false"
          :header="$t('contracts.contraId')"
          :sortable="true"
        >
          <template #body="{ data }">
            <span class="text-right">{{ data.id }}</span>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="number"
              v-model.number="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('global.searchById')"
            />
          </template>
          <template #filterapply="{ field, filterModel }">
            <Button
              :label="$t('global.apply')"
              class="p-button-primary"
              @click="filterApply(field, filterModel)"
            />
          </template>
          <template #filterclear="{ field }">
            <Button
              :label="$t('global.clear')"
              class="p-button-danger"
              @click="filterClear(field)"
            />
          </template>
        </Column>
        <Column
          field="createdDate"
          :header="$t('contracts.contractDate')"
        >
          <template #body="{ data }">
            {{ $dayjs(data.createdDate).format("DD/MM/YYYY") }}
          </template>
        </Column>
        <Column
          field="companyRepresentativeName"
          :header="$t('contracts.companyRep')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyRepresentativeName }}
          </template>
        </Column>
        <Column field="status" :header="$t('global.status')" :sortable="true">
          <template #body="{ data }">
            {{ translatedStatus(data.status) }}
          </template>
        </Column>
        <Column
          field="Actions"
          :header="$t('global.actions')"
          class="w-5"
          :exportable="false"
        >
          <template #body="{ data }">
            <div
              class="flex flex-column md:flex-row justify-content-evenly align-items-center"
            >
              <Button
                :disabled="data.fileId == null"
                class="p-button-text mdi mdi-24px mdi-eye m-2 md:m-0"
                @click="viewContract(data)"
              />
              <Button
                :disabled="data.fileId == null"
                class="p-button-text m-2 md:m-0"
                icon="mdi mdi-24px mdi-download"
                iconPos="right"
                @click="downloadContract(data)"
              />
              <Button
                :class="
                  disabledButton(data)
                    ? 'p-button-secondary'
                    : 'p-button-success'
                "
                :label="$t('contracts.acceptContract')"
                :disabled="disabledButton(data)"
                @click="openAcceptContractView(data)"
                class="m-2 md:m-0"
              />
              <Button
                :label="$t('contracts.rejectContract')"
                @click="rejectContract(data)"
                :disabled="disabledButton(data)"
                :class="
                  disabledButton(data)
                    ? 'p-button-secondary'
                    : 'p-button-danger'
                "
                class="m-2 md:m-0"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData :type="'contracts'"></NoTableData>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";
import ViewContract from "./ViewContract.vue";
import NoTableData from "../NoTableData.vue";
import AcceptContractView from "./AcceptContractView.vue";

export default {
  name: "Contracts table",
  components: {
    ViewContract,
    NoTableData,
    AcceptContractView,
  },
  data() {
    return {
      loading: false,
      contract: null,
      showViewContract: false,
      showAcceptContractView: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  },
  async mounted() {
    this.getContracts();
  },
  computed: {
    ...mapGetters(["contracts", "translatedStatus", "checkRole", "isMobile"]),
  },
  methods: {
    async downloadContract(val) {
      await this.$store
        .dispatch("downloadFile", {
          data: { id: val.fileId, name: val.companyName },
        })
        .then(() => {
          this.$action("contract-download", { contractId: val.id });
        });
    },
    disabledButton(val) {
      return val.status == "accepted" || val.status == "rejected";
    },
    exportCSV() {
      this.$refs.tableContracts.exportCSV();
      this.$action("export-contracts");
    },
    async getContracts() {
      this.loading = true;
      await this.$store.dispatch("contracts");
      if (
        "contractId" in this.$route.query &&
        this.$route.query.contractId != ""
      ) {
        this.filters.id.value = parseInt(this.$route.query.contractId);
      }
      this.loading = false;
    },
    viewContract(data) {
      this.showViewContract = true;
      this.contract = data;
      if (this.checkRole(["client"])) {
        this.$action("contract-view", { contractId: data.id });
      }
    },
    openAcceptContractView(data) {
      this.showAcceptContractView = true;
      this.contract = data;
    },
    closeAcceptContractView(value) {
      (this.showAcceptContractView = false), (this.contract = null);
      if (value == true) {
        this.getContracts();
      }
    },
    async rejectContract(data) {
      let temp = {
        id: data.id,
        applicationId: data.applicationId,
        salesPersonName: data.salesPersonName,
        companyRepresentativeName: data.companyRepresentativeName,
        status: "rejected",
        comments: data.comments,
        version: data.version,
      };
      await this.$store.dispatch("updateContract", temp).then(() => {
        this.$action(
          "contract-reject",
          {
            contractId: temp.id,
            applicationId: temp.applicationId,
          },
          "contract"
        );
      });
      this.$notification(
        this.$t("contracts.updateContractStatus"),
        this.$t("contracts.contractRejected")
      );
      this.$notification(
        this.$t("contracts.updateContractStatus"),
        this.$t("contracts.reloadTableContracts"),
        "info"
      );
    },
    closeContract(value) {
      if (value == true) {
        this.getContracts();
      }
      this.showViewContract = false;
      this.contract = null;
    },
    filterClear(field) {
      this.filters[field].value = null;
    },
    filterApply(field, data) {
      this.filters[field].value = data.value;
    },
  },
};
</script>
