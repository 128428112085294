<template>
  <ContractsTable></ContractsTable>
</template>

<script>
import ContractsTable from "./ContractsTable.vue";
export default {
  name: "Contracts",
  components: {
    ContractsTable
  },
  mounted(){ 
    this.$action("contracts-page-view");
  }
};
</script>


